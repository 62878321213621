<template>
  <div class="company-bg">
    <div class="container">
      <div class="banner">
        <div class="banner-title">
          <span>
            安全文化专家<br />
            答疑解惑</span>
        </div>
        <img src="../../assets/company/banner-eq.png"
             class="banner-eq" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">答疑解惑</li>
          </ol>
        </nav>
        <div class="filter-box">
          <select class="form-select"
                  aria-label="Default select example"
                  @change="toChangeSelect($event, 2)">
            <option value=""
                    selected>按行业</option>
            <option :value="item"
                    v-for="(item, index) in tradeList"
                    :key="index">
              {{ item }}
            </option>
          </select>
          <!-- <select
            class="form-select"
            aria-label="Default select example"
            @change="toChangeSelect($event, 3)"
          >
            <option value="" selected>按地区</option>
            <option
              :value="item.area_id"
              v-for="(item, index) in citys"
              :key="index"
            >
              {{ item.area_name }}
            </option>
          </select> -->

          <SearchBar @toSearch="toSearch"></SearchBar>
          <!-- <div class="search-box">
            <div class="search">
              <i class="iconfont icon-icon-video-search"></i>
              <input type="text"
                     placeholder="请输入关键字搜索"
                     placeholder-class="input-font"
                     @keyup.enter="toSearch" />
              <i class="iconfont icon-clear"
                 @click="clearSearch"></i>
            </div>
          </div> -->
        </div>
        <div class="eq-list">
          <div class="eq-item"
               v-for="item in data.list"
               :key="item.id"
               @click="toEqDetail(item)">
            <div class="eq-title">
              {{item.question}}
            </div>
            <div class="eq-intro">
              <div class="eq-class">{{item.industry}}</div>
              <div class="eq-expert">
                <div class="name">{{ item.expert_name }}</div>
              </div>
            </div>
            <i class="iconfont icon-canyuhuati"></i>
          </div>
        </div>
        <Pagination @change="pageChanged"
                    :current="1"
                    :pageSize="20"
                    :hideOnSinglePage="true"
                    :total="parseInt(data.total)"
                    :showQuickJumper="false"
                    :showTotal="false"
                    placement="center" />
      </div>
    </div>
    <div class="fixed-bottom">
      <a class="bottom-btn"
         @click="showQuestionModal=true"><i class="iconfont icon-tiwen1"></i>留言给专家</a>
    </div>
    <!-- 提问弹出 -->
    <div class="modal modal-question"
         v-if="showQuestionModal"
         id="modal-question">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">留言给专家</h5>
            <button type="button"
                    class="btn-close"
                    @click="showQuestionModal=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="eq-item">
              <div class="eq-title">选择问题所属行业</div>
              <div class="tag-list">
                <div :class="['tag-item', selTrade == item ? 'active' : '']"
                     @click="toSelectTrade(item)"
                     v-for="item in tradeList"
                     :key="item">{{item}}
                </div>
              </div>
              <div class="eq-title">添加问题的标题（5-30字）</div>
              <textarea placeholder="请输入您问题的标题"
                        v-model="questionTitle"
                        maxlength="30"
                        class="form-control mb-2">
              </textarea>
              <div class="eq-title">问题的详情描述（选填）</div>
              <textarea placeholder="请输入您问题的标题"
                        v-model="questionDetail"
                        maxlength="100"
                        rows="8"
                        class="form-control mb-2">
              </textarea>
              <i class="iconfont icon-canyuhuati"></i>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button"
                    @click="showQuestionModal=false"
                    class="btn btn-secondary">
              取消
            </button>
            <button type="button"
                    @click="toSubmit"
                    class="btn btn-primary">
              提交
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 提交成功 -->
    <div class="modal modal-wait"
         id="modal-success"
         v-if="showSuccessModal"
         tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="btn-close"
                    @click="showSuccessModal=false"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            您的问题已提交成功，管理员会定期收集问题，选择具有代表性的问题，邀请行业专家答疑解惑，请耐心等待。
          </div>
          <div class="modal-footer">
            <button type="button"
                    @click="showSuccessModal=false"
                    class="btn btn-primary">我知道了</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showQuestionModal || showSuccessModal"
         class="modal-backdrop show"></div>
  </div>
</template>
<script>
import {
  getBaseData,
  fetchQuestionList,
  submitQuestion
} from "@/network/API.js";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from '@/components/Pagination.vue'

export default {
  components: {
    SearchBar,
    Pagination
  },
  data() {
    return {
      data: {},
      tradeList: [],
      pageNumber: 1,
      searchKey: "",
      selectTrade: "",
      selTrade: "",
      questionTitle: "",
      questionDetail: "",
      showQuestionModal: false,
      showSuccessModal: false,
    };
  },
  async created() {
    this.tradeList = await getBaseData("trade")
    this.getList()
  },
  methods: {
    toSubmit() {
      if (this.selTrade == "") {
        this.$toast("请选择所属行业")
        return
      }

      if (this.questionTitle == "") {
        this.$toast('请填写问题标题')
        return
      }
      let loader = this.$loading.show()
      let res = submitQuestion({
        industry: this.selTrade,
        question: this.questionTitle,
        description: this.questionDetail
      })
      loader.hide()
      this.showQuestionModal = false
      this.showSuccessModal = true
    },
    toSelectTrade(item) {
      if (this.selTrade == item) {
        this.selTrade = ""
      } else {
        this.selTrade = item
      }
    },
    clearSearch() {
      this.pageNumber = 1
      this.getList()
    },
    toSearch(val) {
      this.searchKey = val
      this.pageNumber = 1
      this.getList()
    },
    toChangeSelect(val) {
      this.selectTrade = val.target.value
      this.pageNumber = 1
      this.getList()
    },
    toEqDetail(item) {
      this.$router.push({
        name: "EqDetail",
        params: {
          id: item.id
        },
      })
    },
    async getList() {
      let loader = this.$loading.show()
      this.data = await fetchQuestionList({
        industry: this.selectTrade,
        page_size: 20,
        page: this.pageNumber,
        keyword: this.searchKey
      })
      loader.hide()
    },
    pageChanged(val) {
      this.pageNumber = val.page
      this.getList()
    },
  }
};
</script>
<style scoped>
.banner-eq {
  position: absolute;
  right: 0px;
  top: 40px;
  width: 350px;
  height: 350px;
  display: block;
}

.modal-question .eq-title {
  font-size: 18px;
  margin-bottom: 10px;
}
.tag-list {
  margin: 0 -10px 30px -10px;
}
.tag-item {
  border: 1px solid #3b75ea;
  color: #3b75ea;
  font-size: 14px;
  height: 30px;
  border-radius: 50px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}
.tag-item.active {
  background: #3b75ea;
  color: #fff;
}
.modal {
  display: block;
}
</style>
